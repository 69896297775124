<template>
    <Navbar/>
    <div class="d-flex justify-content-center align-items-center" style="gap:36px; margin-top:120px">
      <PaymentFormMVP/>
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/navbar/NavbarMain.vue'
  import PaymentFormMVP from '@/components/mainBlock/PaymentFormMVP.vue'
  import '@/assets/css/landing.css'

  export default {
    name: 'PaymentMPV',
    components: { Navbar, PaymentFormMVP, },
    
  }
  </script>
  
  <style>
  
  </style>