<template>
    <Navbar/>
    <div class="d-flex justify-content-center align-items-center" style="gap:36px; margin-top:120px">
        <img src="@/assets/images/default_avatar_512.png" height="200" width="200" style="border-radius: 200px; object-fit: cover;">
        <div class="d-flex flex-column" style="gap:24px">
            <div>
                Почта: {{ email }}
            </div>
            <div>
                Дней до конца подписки: {{ daysLeft }}
            </div>
            <a href='/payment' class="btn btn-primary btn-sm no-decor">
                Добавить 30 дней за 10 000 рублей
            </a>
        </div>
    </div>
    
  </template>
  
  <script>
  import Navbar from '@/components/navbar/NavbarMain.vue'
  import { computed } from 'vue';
  import { useUserStore } from '@/store/modules/users';

  export default {
    components: { Navbar, },
    name: 'Profile',
    data() {
      return {
        title: 'Hello, World!'
      }
    },
    setup(){
        const userStore = useUserStore();
        const email = computed(() => {
            return userStore.getEmail
        })
        const daysLeft = computed(() => {
            return userStore.getDaysLeft
        })
        return{
            email,
            daysLeft
        }
  }
}
  </script>
  
  <style>
  
  </style>
  