<template>
  <div class="middle-list mt-3">
    <div
      :id="'list-item-' + clinic.id"
      class="list-item"
      v-for="clinic in clinics"
      :key="clinic.id"
      style="padding: 8px; border: 1px solid aliceblue; cursor: pointer; position: relative;"
      @click="handleClinicClick(clinic)"
      :style="{ backgroundColor: clinicClicked === clinic.id ? '#f5f5f5' : '' }"
    >
      <div class="d-flex justify-content-between w-100">
        <div>{{ clinic.brand.name }}</div>
      </div>
      <div>{{ clinic.raw_address }}</div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useClinicsStore } from '@/store/modules/clinics';
import axios from '@/assets/js/axios.js';
import { useFiltersStore } from '@/store/modules/filters';

export default {
  name: 'ClinicList',
  props: {
    visibleComponent: String,
    rareCategoryFiltersActivated: Boolean,
  },
  setup(props) {
    const clinicsStore = useClinicsStore();
    const filtersStore = useFiltersStore();
    const clinics = computed(() => clinicsStore.getClinics);
    const tooltipClinicId = ref(null);
    const clinicClicked = ref(null); // Track the ID of the clinic clicked
    const category_id = computed(() => filtersStore.category_id);
    const service_name = computed(() => filtersStore.service_name);
    console.log('!!', service_name)
    
    const fetchServices = async (clinicId) => {
      console.log('!', service_name)
      try {
        const response = await axios.get('/api/v1/rows/get_services_by_clinic_id', { 
          params: { 
            clinic_id: clinicId,
            category_id: category_id.value,
            service_name: service_name.value,
          } });
        clinicsStore.setServices(response.data);
        console.log(response.data)
      } catch (error) {
        console.error(error);
      }
    };

    const fetchRareServices = async (clinicId) => {
      try {
        const clinic = clinics.value.find((c) => c.id === clinicId);
        if (clinic) {
          console.log(clinic)
          const categoryIds = clinic.category_set.map((category) => category.id);
          const url = `/api/v1/rows/get_rows_by_clinic_id_and_category_ids?clinic_id=${clinicId}&category_ids=${categoryIds.join(',')}`;
          const response = await axios.get(url);
          clinicsStore.setRareServices(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchNewServices = async (clinicId) => {
      try {
        const clinic = clinics.value.find((c) => c.id === clinicId);
        const days_since_created = filtersStore.getPeriod;
        

        if (clinic) {
          const url = `/api/v1/rows/get_new_rows_by_clinic_id?clinic_id=${clinicId}&days=${days_since_created}`;
          const response = await axios.get(url);
          
          // Ensure response.data is in the expected format
          if (response && response.data) {
            console.log(response.data)
            clinicsStore.setNewServices(response.data);
          } else {
            console.warn('Unexpected response data format:', response);
          }
        } else {
          console.warn(`Clinic with ID ${clinicId} not found`);
        }
      } catch (error) {
        console.error('Error fetching new services:', error);
      }
    };

    const handleClinicClick = (clinic) => {
      clinicClicked.value = clinic.id

      switch (props.visibleComponent) {
        case 'map':
          showClinicOnMap(clinic)
          break;
        case 'serviceList':
          fetchServices(clinic.id)
          fetchNewServices(clinic.id)
          if (props.rareCategoryFiltersActivated){
            fetchRareServices(clinic.id)
          }
          break;
        case 'clinicInfo':
          fetchClinicInfo(clinic.id)
          break;
        default:
          break;
      }
    };

    const showClinicOnMap = (clinic) => {
      if (window.mapInstance && clinic.raw_address) {
        ymaps.geocode(clinic.raw_address).then((result) => {
          const firstGeoObject = result.geoObjects.get(0);
          const coords = firstGeoObject.geometry.getCoordinates();

          // Center the map on clinic coordinates and zoom in
          window.mapInstance.setCenter(coords, 15);
          const balloonContent = `${clinic.brand.name + '<br>'}${clinic.raw_address + '<br>'}`;
          // Create a placemark for the clinic
          const placemark = new ymaps.Placemark(coords, {
            balloonContent: balloonContent,
          }, {
            // Set the balloon offset
            offset: [0, -40], // Adjust the offset as needed
          });

          // Add the placemark to the map
          window.mapInstance.geoObjects.add(placemark);

          // Open the balloon with an offset to show it above the placemark
          placemark.balloon.open(coords, {
            contentHeader: clinic.raw_address,
            closeButton: true,
          }, {
            // Set the balloon offset
            offset: [0, -40], // Adjust the offset as needed
          });
        }).catch((error) => {
          console.error('Error geocoding clinic:', error);
        });
      }
    };

    const fetchClinicInfo = (clinicId) => {
      console.log('Fetching clinic info for ID:', clinicId);
    };

    return {
      clinics,
      tooltipClinicId,
      handleClinicClick,
      fetchRareServices,
      clinicClicked,
    };
  },
};
</script>


<style>

</style>