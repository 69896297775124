import { createApp } from 'vue'
import App from './App.vue';
import router from './routers/Router';
import globalMethods from './plugins/globalMethods'
// import './static/css/_base/bootstrap-icons.css'
import './assets/css/_base/bootstrap.min.css'
// import './assets/css/_base/bootstrap-icons.css'
import './assets/css/_base/fonts_lato.css'
import './assets/css/_base/fonts_roboto.css'
import './assets/css/_base/select2.min.css'
import './assets/css/common.css'
import './assets/css/sidebar.css'
import './assets/css/right_block.css'
import './assets/css/navbar.css'



import './assets/js/common.js'
import './assets/js/chatra.js'
// import './assets/js/metrika.js'
import '@/assets/js/landing.js'
import '@/assets/js/_base/bootstrap.bundle.min.js'
// import './assets/js/_base/jquery.min.js';
import './assets/js/_base/popper.min.js'
import './assets/js/_base/select2.min.js'



import pinia from './store';

const app = createApp(App);

app.use(globalMethods);
app.use(pinia);
app.use(router);
app.mount('#app');

