// stores/filters.js
import { defineStore } from 'pinia';

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    category_id: '',
    service_name: '',
    city_name: '',
    period: '0',
    number_of_clinics: '1'
  }),
  actions: {
    setCategoryId(id) {
      this.category_id = id;
    },
    setServiceName(name) {
      this.service_name = name;
    },
    setCityName(name) {
      this.city_name = name;
    },
    setPeriod(period) {
      this.period = period;
    },
    setNumberOfClinics(number) {
      this.number_of_clinics = number;
    }
  },
  getters: {
    getPeriod() {
      return this.period;
    },
  }
});