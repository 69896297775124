<template>
    <form @submit.prevent="register" class="d-flex flex-column login-form">
        <div class="d-flex flex-column">
            <input v-model="email" id='form-email' class="form-control" name="username" placeholder="email" />
            <div id='form-email-error' style="color:red" hidden>Эта почта уже занята</div>
        </div>
        <input v-model="password" id='form-pass' class="form-control" name="password" type="password" placeholder="Пароль" />
        <div class="d-flex flex-column">
            <input v-model="password2" id='form-pass-2' class="form-control" type="password" placeholder="Пароль еще раз" />
            <div id="pass-error" style="color: red" hidden>Пароли не совпадают</div>
        </div>
        <button type="submit" class="btn btn-primary" :disabled="!isOfertaChecked">Зарегистрироваться</button>
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
        <a class="mt-2" style='text-align:end' href="/login">Вход</a>
        <div class="d-flex align-items-center" style="gap:12px;">
            <input v-model="isOfertaChecked" type="checkbox" id='oferta-checkbox' class="form-input" checked>
            <label for='oferta-checkbox' style="font-size:.64rem">Согласен с <a href="#">правилами</a> обработки персональных данных</label>
        </div>
    </form>
</template>

<script>
import { jwtDecode } from 'jwt-decode';
import axios from '@/assets/js/axios.js';
import { useUserStore } from '@/store/modules/users';

export default {
    name: 'RegisterForm',
    data() {
        return{
            email: '',
            password: '',
            password2: '',
            errorMessage: '',
            isOfertaChecked: true
        }
    },
    methods:{
        async register() {
            const data = new FormData();
            data.append('username', this.email);
            data.append('password', this.password);
            try {
                const pass_field = document.getElementById('form-pass');
                const pass_field_2 = document.getElementById('form-pass-2');
                const pass_error = document.getElementById('pass-error');
                const email_field = document.getElementById('form-email');
                const email_error = document.getElementById('form-email-error');
                if (this.email === '') {
                    email_field.style.border = '1px solid red';
                    email_error.hidden = false;
                    email_error.innerHTML = 'Введите почту';
                } else if (this.password !== this.password2) {
                    pass_field.style.border = '1px solid red';
                    pass_field_2.style.border = '1px solid red';
                    pass_error.hidden = false;
                    pass_error.innerHTML = 'Пароли не совпадают';
                    return;
                } else if (this.password === '' && this.password2 === '') {
                    pass_field.style.border = '1px solid red';
                    pass_field_2.style.border = '1px solid red';
                    pass_error.innerHTML = 'Введите пароль';
                    pass_error.hidden = false;
                    return;
                }
                const response = await axios.post('api/v1/users/register', data);
                
                if ('access_token' in response.data) {
                    const token = response.data.access_token;
                    const decoded = jwtDecode(token);
                    // Decode the token to extract email and paid_till
                    const email = decoded.email;
                    const daysLeft = decoded.days_left;
                    
                    // Update the Pinia store
                    const userStore = useUserStore();
                    userStore.setUser(email, daysLeft);
                    this.errorMessage = '';
                    localStorage.setItem('access_token', response.data.access_token);
                    this.$router.push('/services');
                    document.cookie = `${new_user}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;

                } else if ('error' in response.data) {
                    if (response.data.error === 'user exists') {
                        email_error.hidden = false;
                        email_error.innerHTML = 'Эта почта уже занята';
                        email_field.style.border = '1px solid red';
                    }
                } else {
                    email_error.hidden = false;
                    email_error.innerHTML = 'Что-то пошло не так, напишите в чат';
                }
            } catch (error) {
                // Handle error appropriately
                if (error.response) {
                    // Server responded with a status other than 2xx
                } else if (error.request) {
                    // Request was made but no response received
                } else {
                    // Something happened in setting up the request
                }
            }
        }
    }
}
</script>