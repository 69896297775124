<template>
    <div class="navbar">
      <div class="d-flex align-items-center">
        <a href="/" class="d-flex flex-column no-decor" style="color:unset">
          <div class="d-flex flex-column align-items-start" style="font-size:2.5rem; line-height: normal">
            Знаем все
            <span style="margin-top:-4px; letter-spacing: 9.2px; color:dimgrey; font-size:1rem">стоматологии</span>
          </div>
        </a>
      </div>
    </div>
    <div class="mt-5 m-auto" style="width: 580px; max-width: 95%; position:relative; ">
      <h1>Ищите клиентов на разработку сайтов?</h1>
      
      <div class="d-flex flex-column mt-5" style="gap:48px;">
        <div class="d-flex flex-column" style="gap:18px;">
          <div>
            <label>Наличие сайта</label>
            <select class="form-select js-filter-select" v-model="selections.site" @change="recalculate">
              <option :value="{ counter: 1, price: 1 }">Неважно</option>
              <option :value="{ counter: 0.45, price: 2.2 }" selected>Есть</option>
              <option :value="{ counter: 0.7, price: 1.2 }">Нет</option>
            </select>
          </div>
          <div>
            <label>Качество сайта</label>
            <select class="form-select js-filter-select" v-model="selections.site_quality" @change="recalculate">
              <option :value="{ counter: 1, price: 1 }">Неважно</option>
              <option :value="{ counter: 0.45, price: 2.9 }" selected>Низкое</option>
              <option :value="{ counter: 0.4, price: 2.9 }">Среднее</option>
              <option :value="{ counter: 0.15, price: 1.7 }">Высокое</option>
            </select>
          </div>
        </div>
        <div class="d-flex flex-column" style="gap:18px;">

          <div>
            <label>Прибыль за 2023</label>
            <select class="form-select js-filter-select" v-model="selections.profit" @change="recalculate">
                <option :value="{ counter: 1, price: 1 }">Неважно</option>
                <option :value="{ counter: .02, price: 1.1 }">Убыток от 5 млн</option>
                <option :value="{ counter: .07, price: 1.1 }">Убыток от 1 до 5 млн</option>
                <option :value="{ counter: .15, price: 1.1 }">Убыток до 1 млн</option>
                <option :value="{ counter: .21, price: 1.1 }">Прибыль до 1 млн</option>
                <option :value="{ counter: .19, price: 1.1 }">Прибыль от 1 до 5 млн</option>
                <option :value="{ counter: .24, price: 1.4 }" selected>Прибыль от 5 до 20 млн</option>
                <option :value="{ counter: .12, price: 1.1 }">Прибыль от 20 млн</option>
            </select>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center w-100" style="margin-bottom: 100px;">
            <div class="mb-1">{{ totalCounter }} контактов</div>
            <button class="btn btn-primary w-100" @click="handle_button_click('button clicked')">Оплатить {{ totalPrice }}р </button>
            <div class="mt-2"><strong>В качестве бонуса</strong> при оплате вы получите перечень законодательных требований к медицинским сайтам, что поможет вам продемонстрировать свою компетентность и легче убедить потенциального клиента.
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import { ref, computed, watch } from 'vue'
import axios from '@/assets/js/axios.js'

export default {
  name: 'LandingWithFilters',

  setup() {
    const initialcounter = 5179
    const initialPrice = 1

    // Reactive state for selections
    const selections = ref({
      site: { counter: 0.45, price: 2.2 },
      site_quality: { counter: 0.45, price: 2.9 },
      profit: { counter: .24, price: 1.4 },
    })

    // Computed properties to calculate total counter and price
    const totalCounter = computed(() => {
      return Math.round(
        initialcounter *
          selections.value.site.counter *
          selections.value.site_quality.counter *
          selections.value.profit.counter
      )
    })

    const totalPrice = computed(() => {
      return Math.round(
        initialPrice * totalCounter.value *
          selections.value.site.price *
          selections.value.site_quality.price *
          selections.value.profit.price
      )
    })

    // Recalculate function to handle updates
    const recalculate = () => {
      console.log('!', totalPrice.value)
        totalCounter.value = Math.round(
        initialcounter *
          selections.value.site.counter *
          selections.value.site_quality.counter *
          selections.value.profit.counter
      )
        console.log(totalPrice.value, totalCounter.value)
      totalPrice.value = Math.round(
        totalCounter.value * initialPrice *
          selections.value.site.price *
          selections.value.site_quality.price *
          selections.value.profit.price
      );
    }

    // выставляем значения для связанных полей
    watch(
      () => selections.value.site_quality,
      (newValue) => {
        if (newValue.counter !== 1) {
          selections.value.site = { counter: 0.45, price: 2.2 }
        }
      }
    )

    watch(
      () => selections.value.site,
      (newValue) => {
        if (newValue.counter === 1 || newValue.counter === 0.7) {
          selections.value.site_quality = { counter: 1, price: 1 }
        }
      }
    )
      
    const notifyAction = async (msg) => {
      try {
        let params = {'msg': msg}
        console.log(params)
        const response = await axios.get('api/v1/payments/notify_action', {params: params})
      } catch (error) {
        console.error('Error fetching options:', error)
      }
    };

    const handle_button_click = async (msg) => {
        await notifyAction(msg)

        window.location.href = `/payment_m?amount=${totalPrice.value}&count=${totalCounter.value}`
    }

    return {
      selections,
      totalCounter,
      totalPrice,
      recalculate,
      notifyAction,
      handle_button_click
    };
  },
}
</script>