<template>
  <div id="app1" :style="wrapperStyles">
    <router-view />
  </div>
</template>



<script>
import { loadScript } from '@/assets/js/yandexMaps.js'
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {

  setup() {
    const route = useRoute();
    const excludedRoutes = ['/', '/web_filters']; // List of routes to exclude

    const isRouteExcluded = computed(() => {
      return excludedRoutes.includes(route.path);
    });

    const wrapperStyles = computed(() => {
      return isRouteExcluded.value
        ? {}
        : {
            height: '100vh',
            overflow: 'hidden'
          };
    });
    function adjustHeight() {
      const middleLists = document.querySelectorAll('.middle-list');

      middleLists.forEach(middleList => {
      const contentRect = middleList.getBoundingClientRect();
      const contentTop = contentRect.top
      const viewportHeight = window.innerHeight;

      const availableHeight = viewportHeight - contentTop;

      middleList.style.maxHeight = `${availableHeight}px`;
      middleList.style.Height = `${availableHeight}px`;
    })
  }

    window.addEventListener('resize', adjustHeight);
    window.addEventListener('load', adjustHeight);
    window.addEventListener('click', adjustHeight);
    document.addEventListener('DOMContentLoaded', adjustHeight);
    return {
      wrapperStyles,
      adjustHeight
    };
  },
  mounted() {
    loadScript('https://api-maps.yandex.ru/2.1/?apikey=9ee0b24a-abd5-42f6-a5dd-e756f2f26a3b&lang=ru_RU')
      .then(() => {
        // You can initialize any global settings here if needed
      })
      .catch((error) => {
        console.error('Error loading Yandex Maps API script', error);
      });
  }
};
</script>


<style>
/* #app1 {
  height: 100vh; 
  overflow-y: hidden; 
} */
</style>