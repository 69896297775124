<template>
    <div class="main-block">
    <div class="main-content">
        <div class="payments-main">
            
            <div style="text-align:center; font-weight:700; font-size:1.3rem">Оплата за список стоматологий</div>
            <div class="payment-data">
                <div style="display:flex; gap:30px">
                    <div>Сумма:</div>
                    <div>{{amount}} р.</div>
                </div>
                <div>Продавец: ООО "Юнайтед Сервисис"</div>
            </div>
            <div class="card-images mt-3" style="display:flex; gap:30px">
                <div>
                    <img src="@/assets/images/visa.png" style="width:80px; height:auto; object-fit:cover">
                </div>
                <div>
                    <img src="@/assets/images/mastercard.png" style="width:80px; height:auto; object-fit:cover">
                </div>
            </div>
            <div class="card-number mt-3">
                <div style="flex-shrink: 0">Номер карты: </div>
                <input
                    id='card-number-input'
                    class="form-control"
                    type="text"
                    v-model="formattedInput"
                    @input="formatInput"
                    placeholder="______ ______ ______ ______"
                    maxlength="19"
                    />
            </div>
            <div class="card-info">
                <div class="card-dates">
                    <div style="flex-shrink: 0">Срок действия:</div>
                    <input type="text" class="THIS_IS_AN_MVP SORRY_ABOUT_THAT form-control card-dates-input" placeholder="____/____" disabled style="background-color:white">
                </div>
                <div class="card-csv">
                    <div style="flex-shrink: 0">CVV2/CVC2: </div>
                    <input type="text" class="THIS_IS_AN_MVP SORRY_ABOUT_THAT form-control" placeholder="______" disabled style="background-color:white">
                </div>
            </div>
            <button class="btn btn-warning">Оплатить - {{amount}}р.</button>
            <div style="font-size:.9rem; font-weight:600; margin-top:-10px">Вы будете перенаправлены на защитный протокол для оплаты</div>
            <div style="font-size:0.7rem">Нажимая на кнопку "Оплатить", вы принимаете условия <span style="font-size:0.7rem; color:blue">Оферты</span>.</div>
        </div>
    </div>
</div>
</template>


<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export default {
    name: 'PaymentForm',
    setup() {
        const route = useRoute()
        const amount = route.query.amount || '0'
        const count = route.query.count || '0'
        const formattedInput = ref('')

        const formatInput = (event) => {
        let value = event.target.value.replace(/\D/g, '')

        value = value.slice(0, 16)

        const groups = value.match(/.{1,4}/g)
        if (groups) {
            formattedInput.value = groups.join(' ')
        } else {
            formattedInput.value = value
        }
        }

        $(window).on('focusout', (e)=>{
            if (e.target.id === 'card-number-input'){
                let card_num = document.getElementById('card-number-input').value
                if (card_num.length === 19){
                    console.log("hey")
                }
            }
        })

    return {
    formattedInput,
    formatInput,
    amount,
    count
    }
},
}
</script>
