<template>
    <div>
      <label>Город</label>
      <select ref="select2CityInput" class="form-control"></select>
    </div>
  </template>
  
  <script>
  import axios from '@/assets/js/axios.js';
  import { useFiltersStore } from '@/store/modules/filters';
  import { onMounted, ref, watch } from 'vue';
  
  export default {
    name: 'CitySelect2',
    setup() {
      const filtersStore = useFiltersStore();
      const select2CityInput = ref(null);
  
      const initializeSelect2 = () => {
        axios.get('/api/v1/clinics/select2_get_cities')
          .then(response => {
            let data = response.data;
            data.unshift({ id: '', text: 'Показать все' }); // Prepend an empty value
            
            // Initialize Select2
            $(select2CityInput.value).select2({
              data: data
            });
  
            // Set initial value from filtersStore and trigger change event
            $(select2CityInput.value).val('').trigger('change');
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
  
        // Handle change event
        $(select2CityInput.value).on('change', (event) => {
          filtersStore.setCityName($(event.target).val());
        });
      };
  
      // Watch for changes in filtersStore.city_name and update Select2 value
      watch(
        () => filtersStore.city_name,
        (newValue) => {
          $(select2CityInput.value).val(newValue).trigger('change');
        }
      );
  
      // Initialize Select2 on component mount
      onMounted(() => {
        initializeSelect2();
      });
  
      return {
        select2CityInput
      };
    }
  };
  </script>