// store/modules/clinics.js
import { defineStore } from 'pinia';

export const useClinicsStore = defineStore('clinics', {
  state: () => ({
    clinics: [],
    services: [],
    categories: [],
    rareServices: [],
    newServices: [],
    selectedClinic: null,
  }),

  actions: {
    setClinics(clinics) {
      this.clinics = clinics;
    },
    setCategories(categories) {
      this.categories = categories;
    },
    setServices(services) {
      this.services = services;
    },
    setRareServices(rareServices) {
      this.rareServices = rareServices;
    },
    setNewServices(newServices) {
      this.newServices = newServices;
    },
    resetClinics() {
      this.clinics = [];
    },
    resetRareServices() {
      this.rareServices = [];
    },
    resetNewServices() {
      this.newServices = [];
    },
    setSelectedClinic(clinic) {
      this.selectedClinic = clinic;
    },
    
    
  },
  getters: {
    getClinics() {
      return this.clinics;
    },
    getCategories() {
      return this.categories;
    },
    getServices() {
      return this.services;
    },
    getRareServices() {
      return this.rareServices;
    },
    getNewServices() {
      return this.newServices;
    },
    getSelectedClinic() {
      return this.selectedClinic;
    }
  },
});
