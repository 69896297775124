<template>
    <div id="payment-form" style="width:400px"></div>
  </template>
  
  <script>

import { ref, onMounted } from 'vue';
import axios from '@/assets/js/axios.js';

  export default {
    name: 'PaymentForm',
    setup() {
    const options = ref(null);
    
    const fetchOptions = async () => {
      try {
        let params = {'tariff_id': 1}
        const response = await axios.get('api/v1/payments/get_payment_options', {params: params})
        options.value = response.data;
        console.log('!', options.value)
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    onMounted(async () => {
      await fetchOptions();

      if (options.value) {
        const script = document.createElement('script');
        script.src = 'https://www.payanyway.ru/assistant-builder';
        script.type = 'text/javascript';
        script.onload = () => {
          const assistant = new window.Assistant.Builder();
          
          // Payment successful
          assistant.setOnSuccessCallback((operationId, transactionId) => {
            location.replace("/services");
          });
          
          // Payment failed
          assistant.setOnFailCallback((operationId, transactionId) => {
            location.replace('/payment_error')
          });
          
          // Payment in progress
          assistant.setOnInProgressCallback((operationId, transactionId) => {
            // Handle in progress
          });
          
          assistant.build(options, 'payment-form');
        };
        
        document.head.appendChild(script);
      }
    });
    
    return {};
  }
};
  

    

  </script>