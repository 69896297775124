import axios from 'axios'

const instance = axios.create({
    baseURL: 'https://medapi.znaemvse.com/', 
    // baseURL: 'https://7bdf-87-255-31-97.ngrok-free.app', 
    // baseURL: 'http://127.0.0.1:8000/', 
    withCredentials: true
});


instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      const new_user = getCookie('new_user')
      if (new_user) {
        config.headers.NewUser = `${new_user}`
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      
      console.log('!', error)
      if (!error.response) {
        // Handle network error
        localStorage.removeItem('access_token');
        window.location.href = '/login';
        // Optionally handle retry logic or notify the user about the network issue
      } else if (error.response.status === 401) {
        // localStorage.removeItem('access_token');
        // window.location.href = '/login';
      }
      
      return Promise.reject(error);
    }
  );

export default instance

function getCookie(name) {
  // Split the cookie string into individual cookies
  const cookies = document.cookie.split(';');

  // Loop through the cookies to find the one with the specified name
  for (let cookie of cookies) {
      cookie = cookie.trim(); // Remove any leading/trailing whitespace
      // Check if this cookie starts with the name we are looking for
      if (cookie.startsWith(name + '=')) {
          // Extract and return the cookie value
          return cookie.substring(name.length + 1); // Add 1 to skip '=' character
      }
  }

  // Return null if the cookie with the specified name is not found
  return null;
}