<template>
    <Navbar/>
    <div class="d-flex" style="margin-top:80px;">
      <LoginForm/>
    </div>
    <!-- <h1>{{ title }}</h1> -->
  </template>
  
  <script>
  import Navbar from '@/components/navbar/NavbarMain.vue'
  import LoginForm from '@/components/mainBlock/LoginForm.vue'
  import '@/assets/css/landing.css'

  export default {
    name: 'Login',
    components: { Navbar, LoginForm, },
    
  }
  </script>
  
  <style>
  
  </style>
  