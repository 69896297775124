

<template>
    <div class='middle-list' style="min-width:460px; max-width:460px; overflow-y:scroll">
        <div
        :id="'list-item-' + category.id"
        class="d-flex flex-column"
        v-for="category in categories"
        :key="category.id"
        style="padding:8px; border:1px solid aliceblue; cursor: pointer; position: relative;"
        
      >{{category.index}}: {{ category.name }}</div>
    </div>
</template>

<script>

import { computed } from 'vue';
  import { useClinicsStore } from '@/store/modules/clinics';
  import axios from '@/assets/js/axios.js';
  
  export default {
    name: 'CategoryList',
    props: {
      visibleComponent: String,
      rareCategoryFiltersActivated: Boolean,
    },
    setup(props) {
        
      const clinicsStore = useClinicsStore();
      const categories = computed(() => clinicsStore.categories);
  
     

    return {
        categories,
    };
  },
};
</script>

