<template>
    <div class="d-flex flex-column filters-block">
      <div class='d-flex flex-column'>
        <label>Категория</label>
        <select ref="select2CategoryInput" id="select2-category-input" class="form-control"></select>
      </div>
      <div class="d-flex flex-column">
        <label>Название услуги</label>
        <input v-model="filtersStore.service_name" class="form-control">
      </div>
      <div class="d-flex flex-column">
        <CitySelect2 />
      </div>
      <div class="d-flex flex-column">
        <label>Показазть новинки</label>
        <select v-model="filtersStore.period" class="form-select">
          <option value="0">Показать все услуги</option>
          <option value="7">Новинки за 7 дней</option>
          <option value="30">Новинки за 30 дней</option>
          <option value="30">Новинки за 90 дней</option>
        </select>
      </div>
      <button class="btn btn-primary mt-3" @click="show_clinics_list">Показать</button>
    </div>
  </template>
  
  <script>
  import axios from '@/assets/js/axios.js';
  import { useClinicsStore } from '@/store/modules/clinics';
  import { useFiltersStore } from '@/store/modules/filters';
  import CitySelect2 from '@/components/sidebar/filters/CitySelect2.vue';
  import { ref, onMounted } from 'vue';
  
  export default {
    name: 'ServiceFilters',
    components: {
      CitySelect2
    },
    setup() {
      const filtersStore = useFiltersStore();
      const select2CategoryInput = ref(null);
      
      const show_clinics_list = () => {
        const clinicsStore = useClinicsStore();
        clinicsStore.setClinics([]);
        console.log(filtersStore.$state)
        axios.get('/api/v1/clinics/basic_clinic_filters', { params: filtersStore.$state })
          .then(response => {
            clinicsStore.setClinics(response.data);
          })
          .catch(error => {
            console.error(error);
            // Handle the error here
          });
      };
  
      const initializeCategorySelect2 = () => {
  axios.get('/admin/select2_get_categories_ajax')
    .then(response => {
      let data = response.data;
      data.unshift({ id: '', text: '' }); // Prepend an empty value
      
      // Initialize Select2 with correct data
      const $select2 = $(select2CategoryInput.value);
      $select2.select2({
        data: data
      });

      // Set initial value and trigger change event
      $select2.val(filtersStore.category_id).trigger('change');
      
      // Listen for change event
      $select2.on('change', (event) => {
        filtersStore.setCategoryId(event.target.value);
      });
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
};
  
      onMounted(() => {
        initializeCategorySelect2();
      });
  
      return {
        filtersStore,
        select2CategoryInput,
        show_clinics_list
      };
    }
  };
  </script>