import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    email: '',
    daysLeft: 0,
  }),
  actions: {
    setUser(email, daysLeft) {
      this.email = email;
      this.daysLeft = daysLeft;
    },
    clearUser() {
      this.email = '';
      this.daysLeft = 0;
    },
    
  },
  getters: {
    getEmail() {
      return this.email;
        },
    getDaysLeft() {
        return this.daysLeft
    }
    },
});