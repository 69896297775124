<template>
    <Navbar/>
    <div class="d-flex justify-content-center align-items-center" style="gap:36px; margin-top:120px">
      <PaymentForm/>
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/navbar/NavbarMain.vue'
  import PaymentForm from '@/components/mainBlock/PaymentForm.vue'
  import '@/assets/css/landing.css'

  export default {
    name: 'Payment',
    components: { Navbar, PaymentForm, },
    
  }
  </script>
  
  <style>
  
  </style>