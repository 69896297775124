<template>
  <div class='middle-list' style="width:100%; padding:24px; overflow-y:scroll">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Список услуг</h2>
      <div class="d-flex" style="gap:24px">
        <div v-if='filterType != "none"' :class="['navbar-menu-btn cursor-pointer', { 'active': displayMode === 'all' }]" @click="setDisplayMode('all')">Все услуги</div>
        <div v-if='filterType == "rare"' :class="['navbar-menu-btn cursor-pointer', { 'active': displayMode === 'rare' }]" @click="setDisplayMode('rare')">Только редкие</div>
        <div v-if='filterType == "new"' :class="['navbar-menu-btn cursor-pointer', { 'active': displayMode === 'new' }]" @click="setDisplayMode('new')">Только новинки</div>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th v-if="displayMode == 'new'">Дата добавления</th>
          <th>Категория</th>
          <th>Название</th>
          <th>Цена от</th>
          <th>Цена до</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="service in servicesToDisplay" :key="service.id">
  <td v-if="displayMode == 'new'">{{ service.date_created }}</td>
  <td class="custom-tooltip" @mouseover="fetchMeanPrice(service)">
    {{ service.category ? service.category.index : '-' }}
    <div class="custom-tooltip-text d-flex flex-column align-items-start" :class="tooltipPosition">
      <div class="no-wrap">{{ service.category ? service.category.name : '-' }}</div>
      <div class="no-wrap mt-2 mb-2">Средняя цена 'от': {{ meanPrice.mean_min_price + " ₽" || 'N/A' }}</div>
      <div style="text-align: start">Всего услуг в данной категории в выбранных клиниках: {{ totalNumberOfServicesInCategory }}</div>
      <div class='d-flex flex-column'>
        <div class="bar-chart">
          <div 
            v-for="(bin, index) in meanPrice.price_bins" 
            :key="index" 
            :style="{
                height: calculateBarHeight(bin.count) + 'px',
                backgroundColor: getBinForPrice(service.min_price, meanPrice.price_bins) === bin ? 'blue' : 'white',
                border: '1px solid black',
                width: '10px',
                display: 'inline-block',
                marginRight: '2px'
              }"
              :title="`Count: ${bin.count}, Min: ${bin.min_price_in_bin}, Max: ${bin.max_price_in_bin}`"
          ></div>
        </div>
        <div class="d-flex justify-content-between">
          <div>{{ minBinPrice }}</div>
          <div>{{ maxBinPrice }}</div>
        </div>
      </div>
    </div>
  </td>
  <td>{{ service.name }}</td>
  <td>{{ service.min_price }}</td>
  <td>{{ service.max_price }}</td>
</tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useClinicsStore } from '@/store/modules/clinics';
import axios from '@/assets/js/axios.js';

export default {
  name: 'ServiceList',
  
  setup() {
    const clinicsStore = useClinicsStore();
    const displayMode = ref('all');
    const meanPrice = ref({});
    const tooltipPosition = ref('above');
    
    const getBinForPrice = (price, bins) => {
      if (!bins || bins.length === 0) return null;

      // Check if the price falls into the top bin
      const topBin = bins[bins.length - 1];
      if (price === topBin.bin_end) {
        return topBin;
      }

      // Find the appropriate bin for the price
      return bins.find(bin => price >= bin.bin_start && price < bin.bin_end) || null;
    };

    const maxBinCount = computed(() => {
      return Math.max(...(meanPrice.value.price_bins || []).map(bin => bin.count), 0);
    });

    const calculateBarHeight = (count) => {
      return maxBinCount.value ? (count / maxBinCount.value) * 100 : 0;
    };

    const totalNumberOfServicesInCategory = computed(() => {
      return (meanPrice.value.price_bins || []).reduce((total, bin) => total + bin.count, 0);
    });

    const minBinPrice = computed(() => {
      const prices = (meanPrice.value.price_bins || []).map(bin => bin.min_price_in_bin).filter(price => price !== null);
      return prices.length ? Math.min(...prices) : 'N/A';
    });

    const maxBinPrice = computed(() => {
      const prices = (meanPrice.value.price_bins || []).map(bin => bin.max_price_in_bin).filter(price => price !== null);
      return prices.length ? Math.max(...prices) : 'N/A';
    });

    const fetchMeanPrice = async (service) => {
      if (!service.category || !service.category.id) {
        meanPrice.value = { mean_min_price: 'N/A', mean_max_price: 'N/A' };
        return;
      }

      const clinicIds = clinicsStore.getClinics.map(clinic => clinic.id).join(',');
      const categoryId = service.category.id;

      try {
        const response = await axios.get('/api/v1/categories/get_mean_price_for_category_in_clinics_queryset', {
          params: { clinic_ids: clinicIds, category_id: categoryId },
        });
        meanPrice.value = response.data;
        console.log(meanPrice)
      } catch (error) {
        console.error("Error fetching mean price:", error);
        meanPrice.value = { mean_min_price: 'Error', mean_max_price: 'Error' };
      }
    };

    const filterType = computed(() => {
      if (clinicsStore.getRareServices.length > 0) {
        return 'rare';
      } else if (clinicsStore.getNewServices.length > 0) {
        return 'new';
      } else {
        return 'none';
      }
    });

    const servicesToDisplay = computed(() => {
      if (displayMode.value === 'all') {
        return clinicsStore.getServices;
      } else if (displayMode.value === 'rare') {
        return clinicsStore.getRareServices;
      } else if (displayMode.value === 'new') {
        return clinicsStore.getNewServices;
      }
      return [];
    });

    const setDisplayMode = (mode) => {
      displayMode.value = mode;
    };

    const updateTooltipPosition = (event) => {
      const screenHeight = window.innerHeight;
      if (event.clientY < screenHeight / 2) {
        tooltipPosition.value = 'below';
      } else {
        tooltipPosition.value = 'above';
      }
    };

    onMounted(() => {
      window.addEventListener('mousemove', updateTooltipPosition);
    });

    onUnmounted(() => {
      window.removeEventListener('mousemove', updateTooltipPosition);
    });


    return {
      servicesToDisplay,
      setDisplayMode,
      filterType,
      displayMode,
      fetchMeanPrice,
      meanPrice,
      getBinForPrice,
      calculateBarHeight,
      totalNumberOfServicesInCategory,
      minBinPrice,
      maxBinPrice,
      tooltipPosition,

    };
  },
};
</script>

<style scoped>

.custom-tooltip {
  position: relative;
}

.custom-tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 12px;
  position: absolute;
  z-index: 1;
  left: 60%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size:.9rem;
  width:fit-content;
}

.custom-tooltip-text.above {
  bottom: 125%;
}

.custom-tooltip-text.below {
  top: 125%;
}

.custom-tooltip:hover .custom-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.bar-chart {
  display: flex;
  align-items: flex-end;
  height: 105px;
  /* border: 1px solid #ccc; */
  padding: 2px;
  margin: 10px 20px 0 20px;
}

</style>