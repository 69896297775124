<template>
  <div class="d-flex flex-column max-height">
    <Navbar />
    <SecondaryNavbar 
      @toggleServiceFilters="toggleServiceFilters"
      @toggleRareCategoryFilters="toggleRareCategoryFilters"
      @showMap="showComponent('map')"
      @showServiceList="showComponent('serviceList')"
      @showClinicInfo="showComponent('clinicInfo')"
      :activeFilter="visibleFilter"
      :activeComponent="visibleComponent"
    />
  </div>
  <div class="d-flex">
    <ServiceFilters v-if="visibleFilter === 'service_filters'" />
    <RareCategoryFilters v-if="visibleFilter === 'rare_category_filters'" />
    <div class="middle-block">
      <div class="d-flex justify-content-between" style="padding:18px">
        <div @click="showList('clinicList')" class="d-flex align-items-center cursor-pointer" style="gap:8px">
            <h3 style="">Клиники</h3>
            <div>({{ clinics.length }})</div>
          </div> 
        <div @click="showList('categoryList')" v-if="visibleFilter === 'rare_category_filters'" class="d-flex align-items-center cursor-pointer" style="gap:8px">
            <h3 style="">Категории</h3>
            <div>({{ categories.length }})</div>
          </div> 
      </div>
      <button 
        class='btn btn-sm btn-outline-primary' 
        :disabled="clinics.length === 0"
        @click="downloadExcel"
        style="margin-left:18px"
      >Скачать контакты</button>
      <div>
        <ClinicList v-if="visibleList === 'clinicList'" :visibleComponent="visibleComponent" :visibleList="visibleList" :rareCategoryFiltersActivated="rareCategoryFiltersActivated" />
        <CategoryList v-if="visibleList === 'categoryList'" :visibleList="visibleList" :rareCategoryFiltersActivated="rareCategoryFiltersActivated" />
      </div>
    </div>
    
    <YandexMap v-if="visibleComponent === 'map'" />
    <ServiceList v-if="visibleComponent === 'serviceList'" />
    <ClinicInfo v-if="visibleComponent === 'clinicInfo'" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import Navbar from '@/components/navbar/NavbarMain.vue';
import SecondaryNavbar from '@/components/navbar/SecondaryNavbar.vue';
import ServiceFilters from '@/components/sidebar/filters/ServiceFilters.vue';
import NewFilters from '@/components/sidebar/filters/NewFilters.vue';
import RareCategoryFilters from '@/components/sidebar/filters/RareCategoryFilters.vue';
import ClinicList from '@/components/rightBlock/ClinicList.vue';
import CategoryList from '@/components/rightBlock/CategoryList.vue';
import YandexMap from '@/components/rightBlock/YandexMap.vue';
import ServiceList from '@/components/rightBlock/ServiceList.vue';
import ClinicInfo from '@/components/rightBlock/ClinicInfo.vue';
import { useClinicsStore } from '@/store/modules/clinics';
import { useFiltersStore } from '@/store/modules/filters';
import { useUserStore } from '@/store/modules/users';
import * as XLSX from 'xlsx';

export default {
  components: { 
    Navbar, 
    ServiceFilters, 
    NewFilters,
    RareCategoryFilters, 
    ClinicList, 
    CategoryList, 
    YandexMap, 
    ServiceList, 
    ClinicInfo, 
    SecondaryNavbar 
  },
  name: 'Services',

  setup() {
    const visibleFilter = ref('service_filters')
    const visibleComponent = ref('map')
    const visibleList = ref('clinicList')
    const clinicsStore = useClinicsStore()
    const filtersStore = useFiltersStore()
    const usersStore = useUserStore()

    const clinics = computed(() => clinicsStore.getClinics)
    const categories = computed(() => clinicsStore.getCategories)
    
    const email = usersStore.getEmail
    
    const toggleServiceFilters = () => {
      if (visibleFilter.value !== 'service_filters') {
        visibleFilter.value = 'service_filters'
        clinicsStore.resetClinics()
        clinicsStore.resetRareServices()
      }
    };
    const downloadExcel = () => {
      const clinicData = clinics.value.map(clinic => ({
        Город: clinic.city,
        Адрес: clinic.raw_address,
        Сеть: clinic.brand.name,
        Телефоны: clinic.phone_set.map(phone => phone.phone).join('\n'),
        Емейлы: clinic.email_set.map(email => email.email).join('\n'),
      }));

      const ws = XLSX.utils.json_to_sheet(clinicData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "Клиники")
      XLSX.writeFile(wb, "Клиники.xlsx")
    };

    const toggleRareCategoryFilters = () => {
      if (visibleFilter.value !== 'rare_category_filters') {
        visibleFilter.value = 'rare_category_filters'
        clinicsStore.resetClinics()
        clinicsStore.resetNewServices()
      }
    };
    const rareCategoryFiltersActivated = computed(() => {
      return visibleFilter.value === 'rare_category_filters'
    });

    const showList = (component) => {
      visibleList.value = component;
    };
    const showComponent = (component) => {
      visibleComponent.value = component
    };

    return {
      showList,
      showComponent,
      visibleFilter,
      visibleComponent,
      visibleList,
      toggleServiceFilters,
      toggleRareCategoryFilters,
      rareCategoryFiltersActivated,
      clinics,
      categories,
      downloadExcel
    };
  }
};
</script>

<style scoped>
label{
  font-size:.9rem
}
</style>

