export function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = () => {
        resolve();
        document.dispatchEvent(new Event('ymaps-loaded'));
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }