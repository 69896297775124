<template>
    <div class="middle-list dashboard-main w-100" style="padding:12px; gap:12px; overflow-y: scroll;">
        <h2 class="mb-4 w-100">Твоя улыбка <span style='color:grey; font-size:1rem'>(стоматологическая клиника)</span>
        </h2>
        <h4 class="w-100">Информация о юр. лице</h4>
        <div class="block-shadow" style="width:33%">
            <h4>Реквизиты</h4>
            <div class="d-flex flex-wrap">
                <div class="w-50">Дата создания</div>
                <div class="w-50">21.01.2015</div>
                <div class="w-50">ИНН</div>
                <div class="w-50">5047150230</div>
                <div class="w-50">КПП</div>
                <div class="w-50">504701101</div>
                <div class="w-50">ОГРН</div>
                <div class="w-50" style="overflow:hidden">1145033000405</div>
                <div class="w-50">ОКВЭД</div>
                <div class="w-50">83.20</div>

                <div class="mt-3 w-100">
                    <strong>Юридический адрес</strong>
                    <div>141407, Московская область, г. Химки, ул. Молодежная, дом 13А, помещение 4</div>
                </div>
                <div class="mt-3 w-100">
                    <strong>Количество сотрудников</strong>
                    <div class="d-flex flex-wrap justify-content-between">
                        <div class="w-50">2023</div>
                        <div class="w-50">5</div>
                        <div class="w-50">2022</div>
                        <div class="w-50">4</div>
                        <div class="w-50">2021</div>
                        <div class="w-50">3</div>
                    </div>
                </div>
            </div>
        </div>
        <div class=" d-flex flex-wrap" style=" gap: 12px; height: fit-content; flex:0 0 calc(60% - 12px)">
            <div class="block-shadow w-33-with-gap">
                <strong>Уставный капитал</strong>
                <div>500 тыс</div>
                <div>с.п.о. — 200 тыс</div>
            </div>
            <div class="block-shadow w-33-with-gap">
                <strong>Баланс</strong>
                <div>50,32 млн <span style="color:grey">(2023)</span></div>
                <div><span style="color:green">+7,23</span> млн <span style='color:grey'>(16,21%)</span></div>
                <div>с.п.о. — 40,24 млн</div>
            </div>
            <div class="block-shadow w-33-with-gap">
                <strong>Выручка</strong>
                <div>35,85 млн <span style="color:grey">(2023)</span></div>
                <div><span style="color:red">-2,11</span> млн <span style='color:grey'>(-3,81%)</span></div>
            </div>
            <div class="block-shadow w-33-with-gap">
                <strong>Чистая прибыль</strong>
                <div>13,92 млн <span style="color:grey">(2023)</span></div>
                <div><span style="color:green">+1,41</span> млн <span style='color:grey'>(11,2%)</span></div>
            </div>
            <div class="block-shadow w-33-with-gap">
                <strong>Налоги</strong>
                <div>2,12 млн <span style="color:grey">(2023)</span></div>
                <div><span style="color:green">+528</span> тыс <span style='color:grey'>(20,78%)</span></div>
            </div>
            <div class="block-shadow w-33-with-gap">
                <strong>Взносы</strong>
                <div>402,82 млн <span style="color:grey">(2023)</span></div>
                <div><span style="color:red">-302,98</span> тыс <span style='color:grey'>(-44,38%)</span></div>
            </div>

        </div>
        <h4 class="w-100 mt-5">Отзывы</h4>
        <div class="reviews d-flex w-100 flex-wrap">
            <div class="d-flex w-100">
                <div class="block-shadow">
                    <div><strong>Yandex</strong> <span style='color:grey'>4.9 (реальный - 3.7)</span></div>
                </div>
                <div class="block-shadow">
                    <div><strong>Google</strong> <span style='color:grey'>4.5</span></div>
                </div>
                <div class="block-shadow">
                    <div><strong>Отзовик</strong> <span style='color:grey'>4.4</span></div>
                </div>
                <div class="block-shadow">
                    <div><strong>irecommend</strong> <span style='color:grey'>4.4</span></div>
                </div>
            </div>
            <div class="block-shadow" style="flex: 0 0 calc(50% - 32px)">
                <h5 style="color:green">Позитивные от клиентов <span
                        style='font-size:.8rem; color:grey;'>(обобщиение)</span></h5>
                <div>— Высокий уровень профессионализма врачей</div>
                <div>— Качество медицинских услуг</div>
                <div>— Эффективные назначения препаратов</div>
                <div>— Чистота и порядок в клинике</div>
                <div>— Удобство расположения клиник</div>
                <div>— Современное оборудование</div>
                <div>— Детальные анализы</div>
                <div>— Возможность сдать анализы на месте</div>
                <div>— Наличие различных специалистов в одном месте</div>
                <div>— Удобная запись на приемы</div>
            </div>

            <div class="block-shadow" style="flex: 0 0 calc(50% - 32px)">
                <h5 style="color:red">Негативные от клиентов <span
                        style='font-size:.8rem; color:grey;'>(обобщиение)</span></h5>
                <div>— Плохо работают диспетчеры — долго дозваниваются и дают неверную информацию.</div>
                <div>— Навязывают ненужные анализы и процедуры.</div>
                <div>— Высокие и непрозрачные цены, которые меняются без предупреждения.</div>
                <div>— Грубое и непрофессиональное поведение врачей.</div>
                <div>— Частые сбои в работе системы записи и личного кабинета.</div>
                <div>— Задержки приема — пациенты ждут долго, несмотря на запись.</div>
                <div>— Отсутствие клиентоориентированности — невнимательное и хамское отношение персонала.</div>
                <div>— Проблемы с возвратом средств и оплатой услуг — путаница в бухгалтерии.</div>
                <div>— Несогласованность между врачами и отделами клиники.</div>
                <div>— Ошибки в диагнозах и лечении, что приводит к дополнительным расходам.</div>
            </div>
            <div class="block-shadow" style="flex: 0 0 calc(50% - 32px)">
                <h5 style="color:green">Позитивные от сотрудников <span
                        style='font-size:.8rem; color:grey;'>(обобщиение)</span></h5>
                <div>— Зарплата вовремя</div>
                <div>— Отличный коллектив</div>
                <div>— Возможность составить удобный график</div>
            </div>
            <div class="block-shadow" style="flex: 0 0 calc(50% - 32px)">
                <h5 style="color:red">Негативные от сотрудников <span
                        style='font-size:.8rem; color:grey;'>(обобщиение)</span></h5>
                <div>— Слишком строгая система отчетности</div>
                <div>— Низкая зарплата</div>
            </div>
        </div>
        <div class="w-100 d-flex">
            <div class="w-100 block-shadow">
                <h4>Стоимость аренды помещения <span style="color:grey; font-size:.8rem">(предположительно)</span></h4>
                <div>1 200 000 рублей в месяц</div>
            </div>
        </div>
        <div class="w-100 d-flex flex-wrap" style="gap: 12px;">
            <h5 class="w-100">Онлайн</h5>
            <div class="d-flex" style="flex: 0 0 calc(50% - 12px)">
                <div class="d-flex flex-column">
                    <div class="w-100 block-shadow">
                        <div class="d-flex flex-wrap w-100 justify-content-between">
                            <h4 class="w-100">Сайт</h4>
                            <div class="w-50">Адрес</div>
                            <div class="w-25">dental.ru</div>
                            <div class="w-50">Качество</div>
                            <div class="w-25"><span style="color:red">низкое</span></div>
                            <div class="w-50"><span style='color:red;'>&#9888; Соответствие законодательству</span>
                            </div>
                            <div class="w-25"><span style="color:red">скорее нет</span></div>
                            <div class="w-50">СЕО</div>
                            <div class="w-25"><span style="color:red">слабо</span></div>
                            <div class="w-50">Framework</div>
                            <div class="w-25"><span style="color:grey">Bitrix</span></div>
                            <hr class="w-100">
                            <div class="w-50">Посетителей в месяц</div>
                            <div class="w-25"><span style="color:grey">2848</span></div>
                            <div class="w-50">Просмотров старниц в месяц</div>
                            <div class="w-25"><span style="color:grey">7266</span></div>
                            <div class="w-75">Поиск по бренду в месяц (вордстат)</div>
                            <div class="w-25"><span style="color:grey">1245</span></div>
                            <hr class="w-100">
                            <div class="w-75">Дата регистрации домена</div>
                            <div class="w-25"><span style="color:grey">21.10.2023</span></div>
                            <div class="w-75">Оплачен до</div>
                            <div class="w-25"><span style="color:red">21.10.2024</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-wrap" style="flex: 0 0 calc(50% - 12px)">
                <div class="d-flex flex-column">
                    <div class="block-shadow" style="flex: 0 0 calc(50% - 12px)">
                        <h4 class="w-100">Социальные сети</h4>
                        <div class="d-flex flex-wrap justify-content-between" style="flex: 0 0 calc(50% - 32px)">
                            <strong class="w-100">VK</strong>
                            <div class="w-50">Качество</div>
                            <div class="w-25"><span style="color:red">низкое</span></div>
                            <div class="w-50">Кол-во постов за 120 дней</div>
                            <div class="w-25"><span style="color:grey">4</span></div>
                            <div class="w-50">Кол-во подписчиков</div>
                            <div class="w-25"><span style="color:grey">520</span></div>
                        </div>
                        <div class="d-flex flex-wrap mt-4 justify-content-between" style="flex: 0 0 calc(50% - 32px)">
                            <strong class="w-100">Instagram</strong>
                            <div class="w-50">Качество</div>
                            <div class="w-25"><span style="color:red">низкое</span></div>
                            <div class="w-50">Кол-во постов за 120 дней</div>
                            <div class="w-25"><span style="color:grey">8</span></div>
                            <div class="w-50">Кол-во подписчиков</div>
                            <div class="w-25"><span style="color:grey">2120</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex w-100">
            <div class="block-shadow position-relative w-50">
                <img src="@/assets/images/icons/notification.png" class="notif-icon">
                <h5>Мобильное приложение</h5>
                <div class="" style="color:red">отсутствует</div>
            </div>
            <div class="block-shadow w-50">
                <h5><span style='color:red;'>&#9888;</span> Товарный знак</h5>
                <div class="" style="color:red">Не зарегистрирован</div>
            </div>
        </div>
        <div class="w-100 block-shadow">
            <h5>Лицензии</h5>
            <div class="">Л041-01137-77/00341620 от 04.08.2021</div>
        </div>
        <div class="w-100 block-shadow">
            <h5>Агрегаторы</h5>
            <div class="">НаПоправку</div>
            <div class="">32top</div>
        </div>
        <div class="w-100 block-shadow">
            <h5>Обучающие компании</h5>
            <div class="">Dental guru</div>
            <div class="">Stom Academy</div>
            <div class="">Лазеры и Технологии</div>
        </div>
        <div class="w-100 block-shadow position-relative">
            <img src="@/assets/images/icons/notification.png" class="notif-icon">
            <h5>Оборудование</h5>
            <div class="">Трековая аналитическая система лабораторной диагностики</div>
            <div class="">Компьютерный томограф</div>
            <div class="">КТ для стоматологии</div>
            <div class="">УЗИ аппарат fibroscan 530</div>
            <div class="">Система ангиографическая Artis One</div>
            <div class="">Yag-лазер Zeiss</div>
            <div class="">Аппарат для проведения внутрибрюшной гипертермической химиотерапии Hipec</div>
        </div>
        
        <div class="w-100 block-shadow position-relative">
            <img src="@/assets/images/icons/notification.png" class="notif-icon">
            <h5>Материалы и технологии</h5>
            <div class="">Серебрение Saforid</div>
            <div class="">Пломбы из СИЦ</div>
            <div class="">OptraGate</div>
            <div class="">Zoom 4</div>
        </div>
        <div class="w-100 block-shadow position-relative">
            <img src="@/assets/images/icons/notification.png" class="notif-icon">
            <h5>Лаборатории</h5>
            <div class="">Инвитро</div>
            <div class="">Гемотест</div>
            <div class="">KDL</div>
        </div>
        <div class="w-100 block-shadow">
            <h5>Участие в выствках</h5>
            <div class="">2023 — Дентал-Экспо (Москва)</div>
            <div class="">2023 — Дентал Салон (Москва)</div>
            <div class="">2022 — Дентал-Экспо (Москва)</div>
        </div>
        <div class="w-100 block-shadow d-flex flex-column" style="gap:18px;">
            <h5>Врачи</h5>
            <div class="d-flex flex-column">
                <div class="">Иванов Олег Николаевич — стоматолог-ортадонт</div>
                <div style="color: darkgrey">Среднее кол-во приемов в день — 8</div>
                <span style="color:grey; font-size:.8rem; text-decoration: dotted; cursor:pointer">подробнее</span>
            </div>
            <div class="d-flex flex-column">
                <div class="">Никитина Ольга Ивановна — стоматолог-ортопед</div>
                <div style="color: darkgrey">Среднее кол-во приемов в день — 9.5</div>
                <span style="color:grey; font-size:.8rem; text-decoration: dotted; cursor:pointer">подробнее</span>
            </div>
        </div>
        <div class="w-100 block-shadow">
            <h5>Банки-партнеры</h5>
            <div class="">Альфа-Банк</div>
            <div class="">ВТБ</div>
            <div class="">Сбер</div>
        </div>
        <div class="w-100 block-shadow">
            <h5>Страховые-партнеры</h5>
            <div class="">Ингосстрах</div>
            <div class="">Страховой Дом ВСК</div>
        </div>
        <div class="w-100 block-shadow">
            <h5 class="mb-4">Рекомендации по развитию бизнеса</h5>

            <h6>1. Улучшить клиентский сервис</h6>
            <ul>
                <li><strong>Обучение персонала:</strong> Обучить врачей и администраторов навыкам общения с пациентами,
                    повышая клиентоориентированность. Уделить внимание вежливости, терпению и грамотной коммуникации с
                    клиентами.</li>
                <li><strong>Сокращение времени ожидания:</strong> Оптимизировать запись на прием и сократить ожидание
                    пациентов через улучшение логистики внутри клиники.</li>
                <li><strong>Улучшение работы контакт-центра:</strong> Повысить качество обслуживания операторов и
                    внедрить стандарты общения, чтобы уменьшить негативные отзывы о холодности и грубости.</li>
            </ul>

            <h6>2. Прозрачное ценообразование</h6>
            <ul>
                <li><strong>Информирование пациентов о стоимости услуг заранее:</strong> На всех этапах взаимодействия с
                    клиентами (сайт, запись по телефону, прием) пациенты должны получать полную информацию о стоимости
                    процедур. Это поможет снизить уровень недовольства из-за неожиданных счетов.</li>
                <li><strong>Минимизация навязывания ненужных услуг:</strong> Оптимизировать процесс предоставления
                    услуг, предлагая пациентам только действительно необходимые анализы и процедуры. Это повысит доверие
                    и лояльность к клинике.</li>
            </ul>

            <h6>3. Разработка программ лояльности</h6>
            <ul>
                <li><strong>Программы лояльности для постоянных клиентов:</strong> Предлагать скидки или бонусы для
                    повторных посещений, что стимулирует долгосрочные отношения с пациентами.</li>
                <li><strong>Акции и специальные предложения:</strong> Периодически проводить акции (например, скидки на
                    популярные услуги или анализы), что может привлечь новых клиентов и увеличить количество посещений.
                </li>
            </ul>

            <h6>4. Улучшение ИТ-инфраструктуры</h6>
            <ul>
                <li><strong>Обновление CRM-системы:</strong> Улучшить систему учета данных пациентов, запись на прием и
                    связь с клиентами. Современная CRM-система может повысить эффективность обслуживания и снизить
                    количество ошибок.</li>
                <li><strong>Развитие онлайн-сервисов:</strong> Упростить доступ к услугам через личные кабинеты,
                    онлайн-запись и получение результатов анализов. Надежная и удобная онлайн-система станет
                    конкурентным преимуществом.</li>
            </ul>

            <h6>5. Расширение спектра услуг</h6>
            <ul>
                <li><strong>Внедрение новых технологий в стоматологии:</strong> Рассмотреть внедрение передовых
                    технологий (3D-сканирование, лазерное лечение, эстетическая стоматология), что привлечет клиентов,
                    ищущих инновационные решения.</li>
                <li><strong>Специализация на сложных и дорогостоящих процедурах:</strong> Расширить спектр
                    высокомаржинальных процедур, таких как имплантология и эстетическая стоматология.</li>
            </ul>

            <h6>6. Маркетинг и репутация</h6>
            <ul>
                <li><strong>Работа с отзывами:</strong> Постоянно отслеживать и анализировать отзывы, быстро реагировать
                    на негативные комментарии. Важно решать проблемы клиентов и предлагать компенсации в случае ошибок.
                </li>
                <li><strong>Улучшение онлайн-присутствия:</strong> Активное продвижение через соцсети, рекламные
                    кампании и SEO, что поможет привлекать новых клиентов и повысит видимость клиники в интернете.</li>
                <li><strong>Мониторинг репутации:</strong> Внедрить систему отслеживания онлайн-упоминаний клиники на
                    сторонних платформах, чтобы своевременно реагировать на негатив и предотвращать репутационные
                    кризисы.</li>
            </ul>

            <h6>7. Оптимизация внутренней структуры</h6>
            <ul>
                <li><strong>Повышение эффективности взаимодействия между отделами:</strong> Создать более эффективные
                    процессы между администрацией, врачами и бухгалтерией для снижения ошибок при расчете стоимости
                    услуг и обработке клиентских запросов.</li>
                <li><strong>Обучение и мотивация персонала:</strong> Внедрить программы регулярного повышения
                    квалификации врачей и администраторов, а также создать систему бонусов, основанную на результатах
                    работы и удовлетворенности клиентов.</li>
            </ul>

            <h6>8. Оптимизация финансовой политики</h6>
            <ul>
                <li><strong>Анализ маржинальности услуг:</strong> Пересмотреть текущие тарифы на услуги, выявить самые
                    доходные и сократить те, что приносят наименьшую прибыль. Это поможет улучшить финансовую
                    устойчивость.</li>
                <li><strong>Контроль затрат:</strong> Уменьшить затраты на административные процессы, улучшив их
                    автоматизацию и оптимизацию.</li>
            </ul>

            <h6>9. Проведение регулярных исследований клиентской базы</h6>
            <ul>
                <li><strong>Опросы клиентов:</strong> Проводить регулярные исследования среди текущих клиентов, чтобы
                    узнать их мнение о качестве услуг, выявить неудовлетворенные потребности и возможные улучшения.</li>
                <li><strong>Анализ причин оттока клиентов:</strong> Опрашивать пациентов, которые перестали посещать
                    клинику, чтобы выявить основные причины оттока и оперативно решать проблемы.</li>
            </ul>

            <h6>10. Юридическая безопасность</h6>
            <ul>
                <li><strong>Минимизация юридических рисков:</strong> Улучшить качество работы с пациентами, чтобы
                    снизить вероятность жалоб и судебных разбирательств. Важно отслеживать законодательные изменения и
                    обеспечивать полное соответствие всем требованиям.</li>
                <li><strong>Регистрация товарного знака</strong></li>
                <li><strong>привести сайт в соответствие с действующим законодательством</strong></li>
            </ul>

            <h6>Ожидаемые результаты</h6>
            <ol>
                <li><strong>Повышение удовлетворенности клиентов:</strong> Улучшение сервиса, прозрачность в
                    ценообразовании и внимание к отзывам помогут привлечь больше постоянных клиентов и снизить отток.
                </li>
                <li><strong>Рост доходов:</strong> Расширение спектра услуг, привлечение новых клиентов через акции и
                    программы лояльности, улучшение онлайн-систем помогут увеличить выручку.</li>
                <li><strong>Улучшение репутации:</strong> Своевременная работа с отзывами и улучшение клиентского опыта
                    приведут к повышению уровня доверия к клинике, что позитивно отразится на её популярности.</li>
                <li><strong>Оптимизация затрат:</strong> Улучшение внутренних процессов, повышение эффективности
                    CRM-системы и сокращение ненужных расходов увеличат прибыль.</li>
            </ol>

        </div>
        <div class="w-100 block-shadow">
            <h5 class="mb-4">Рекомендации на основе анализа конкурентов</h5>
            <div class="mb-3">Чтобы выделиться среди конкурентов, важно сконцентрироваться на тех областях, где у них наблюдаются слабости, и превратить это в ваши конкурентные преимущества. Вот несколько стратегий, которые помогут вашей клинике выделяться на фоне конкурентов:</div>
            <h6>1. Превосходное обслуживание по ОМС</h6>
            <ul>
                <li><strong>Слабость конкурентов:</strong>
                    <ul>
                        <li>Частые жалобы на навязывание платных услуг при лечении по ОМС.</li>
                        <li>Низкое качество бесплатных услуг и неравномерный уровень обслуживания.</li>
                        <li>Долгие ожидания даже для экстренных случаев.</li>
                    </ul>
                </li>
                <li><strong>Стратегия:</strong>
                    <ul>
                        <li><em>Фокус на качество бесплатного лечения:</em> обеспечьте высокое качество лечения по ОМС, не навязывая платные услуги. Если пациенту что-то действительно нужно за дополнительную плату, это должно быть четко обосновано.</li>
                        <li><em>Скорость и доступность:</em> минимизируйте ожидание для пациентов с острой болью, предлагая приоритетный прием для таких случаев.</li>
                        <li><em>Транспарентность и доверие:</em> создайте репутацию клиники, где пациенты уверены в честности и не боятся, что их попытаются заставить платить больше, чем нужно.</li>
                    </ul>
                </li>
            </ul>

            <h6>2. Отличный сервис и культура обслуживания</h6>
            <ul>
                <li><strong>Слабость конкурентов:</strong>
                    <ul>
                        <li>Грубое или некомпетентное поведение персонала на ресепшене и администраторов.</li>
                        <li>Жалобы на отсутствие вежливости и внимания со стороны врачей и сотрудников.</li>
                    </ul>
                </li>
                <li><strong>Стратегия:</strong>
                    <ul>
                        <li><em>Премиальный сервис для всех:</em> ваше обслуживание должно напоминать уровень частной клиники.</li>
                        <li><em>Инвестиции в обучение персонала:</em> регулярно проводите тренинги по клиентоориентированности и навыкам общения.</li>
                        <li><em>Персонализированный подход:</em> создайте систему, при которой каждый пациент будет чувствовать себя особенным.</li>
                    </ul>
                </li>
            </ul>

            <h6>3. Гибкость и доступность платных услуг</h6>
            <ul>
                <li><strong>Слабость конкурентов:</strong>
                    <ul>
                        <li>Высокие цены на платные услуги, которые не всегда соответствуют качеству.</li>
                        <li>Отсутствие разницы в подходе к бесплатным и платным пациентам.</li>
                    </ul>
                </li>
                <li><strong>Стратегия:</strong>
                    <ul>
                        <li><em>Гибкие тарифы и пакеты услуг:</em> предложите широкий спектр цен на платные услуги.</li>
                        <li><em>Выразительное отличие платных услуг:</em> сделайте так, чтобы платные пациенты действительно чувствовали разницу в уровне обслуживания.</li>
                        <li><em>Программы лояльности:</em> разработайте бонусные программы для постоянных клиентов.</li>
                    </ul>
                </li>
            </ul>

            <h6>4. Чёткая организация записи и приемов</h6>
            <ul>
                <li><strong>Слабость конкурентов:</strong>
                    <ul>
                        <li>Жалобы на длинные очереди, несмотря на запись.</li>
                        <li>Некорректная организация работы — пациенты часто ждут в кабинетах.</li>
                    </ul>
                </li>
                <li><strong>Стратегия:</strong>
                    <ul>
                        <li><em>Оптимизация процесса записи:</em> используйте передовые технологии для организации записи пациентов.</li>
                        <li><em>Безочередной прием для экстренных случаев:</em> предложите систему быстрого приёма.</li>
                        <li><em>Четкость в расписании:</em> минимизируйте ожидание пациентов, следите за соблюдением расписания приема.</li>
                    </ul>
                </li>
            </ul>

            <h6>5. Упор на профилактику и долгосрочные отношения с пациентами</h6>
            <ul>
                <li><strong>Слабость конкурентов:</strong>
                    <ul>
                        <li>Многие конкуренты не уделяют должного внимания профилактическим мерам.</li>
                    </ul>
                </li>
                <li><strong>Стратегия:</strong>
                    <ul>
                        <li><em>Профилактические программы:</em> разработайте и продвигайте программы профилактики для пациентов.</li>
                        <li><em>Долгосрочные планы лечения:</em> предлагайте пациентам комплексные планы лечения и обслуживания.</li>
                        <li><em>Программы напоминаний:</em> автоматически напоминайте пациентам о необходимости посещения для профилактического осмотра.</li>
                    </ul>
                </li>
            </ul>

            <h6>6. Внедрение современных технологий и инноваций</h6>
            <ul>
                <li><strong>Слабость конкурентов:</strong>
                    <ul>
                        <li>Не все клиники активно используют новейшие технологии.</li>
                    </ul>
                </li>
                <li><strong>Стратегия:</strong>
                    <ul>
                        <li><em>Продвижение высоких технологий:</em> позиционируйте свою клинику как современную, активно внедряющую новейшие технологии.</li>
                        <li><em>Продвинутые методы обезболивания:</em> акцентируйте внимание на вашем высоком уровне анестезии.</li>
                        <li><em>Цифровая стоматология:</em> внедрение цифровых технологий для протезирования и диагностики.</li>
                    </ul>
                </li>
            </ul>

            <h6>7. Честность и открытость в вопросах лечения и оплаты</h6>
            <ul>
                <li><strong>Слабость конкурентов:</strong>
                    <ul>
                        <li>Жалобы на внезапные доплаты и отсутствие чёткого понимания стоимости услуг.</li>
                    </ul>
                </li>
                <li><strong>Стратегия:</strong>
                    <ul>
                        <li><em>Прозрачность оплаты:</em> внедрите политику полной прозрачности — пациенты должны знать точную стоимость лечения заранее.</li>
                        <li><em>План лечения с ценами:</em> предоставляйте каждому пациенту детальный план лечения с чётким указанием стоимости всех этапов.</li>
                        <li><em>Гарантии на услуги:</em> предлагайте гарантию на определённые виды лечения.</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>


</template>


<script>

export default {
    name: 'ClinicInfo',
}

</script>

<style scoped>
.dashboard-main {

    font-size: .9rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.w-33-with-gap {
    flex: 1 0 calc(30.33% - 12px)
}

.flex-child {
    flex: 1 0 21%;
}

h6 {
    text-decoration: underline;
}

.notif-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    height: 24px;
    width: 24px;
    opacity: .5;
    cursor: pointer;
}
</style>