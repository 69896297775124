<template>
  <div class='d-flex align-items-center navbar' style="background-color: aliceblue; z-index: 999">
    <div class="d-flex" style="gap:36px">
      <div :class="['navbar-menu-btn cursor-pointer', { 'active': activeFilter === 'service_filters' }]" @click="$emit('toggleServiceFilters')">Фильтр по услугам</div>
      <div :class="['navbar-menu-btn cursor-pointer', { 'active': activeFilter === 'rare_category_filters' }]" @click="$emit('toggleRareCategoryFilters')">Редкие категории</div>
    </div>
    <div class="d-flex" style="gap:36px">
      <div :class="['navbar-menu-btn cursor-pointer', { 'active': activeComponent === 'map' }]" @click="$emit('showMap')">Карта</div>
      <div :class="['navbar-menu-btn cursor-pointer', { 'active': activeComponent === 'serviceList' }]" @click="$emit('showServiceList')">Список услуг</div>
      <div :class="['navbar-menu-btn cursor-pointer', { 'active': activeComponent === 'clinicInfo' }]" @click="$emit('showClinicInfo')">Информация о клинике</div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'SecondaryNavbar',
    props: {
    activeFilter: {
      type: String,
      required: true
    },
    activeComponent: {
      type: String,
      required: true
    }
  },
  }
  </script>
  
  <style>
  .navbar-menu-btn.active {
    text-decoration: underline;
  }
  </style>