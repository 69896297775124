<template>
    <form @submit.prevent="login" class="d-flex flex-column login-form">
        <input v-model="email" class="form-control" name="username" placeholder="email" />
        <input v-model="password" class="form-control" name="password" type="password" placeholder="Пароль" />
        <button type="submit" class="btn btn-primary">Войти</button>
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
        <a class="mt-2" style='text-align:end' href="/register">Регистрация</a>
    </form>
  </template>
  
  <script>
  import { jwtDecode } from "jwt-decode";
  import axios from '@/assets/js/axios.js'
  import { useUserStore } from '@/store/modules/users';

  export default {
    name: 'LoginForm',
    data() {
        return{
            email: '',
            password: '',
            errorMessage: ''
        }
    },
    methods:{

        async login(){
            const data = new FormData()
            data.append('username', this.email)
            data.append('password', this.password)
            data.append('is_vue', 1)
            try {
                const response = await axios.post('api/v1/users/token', data);
                if ('access_token' in response.data){
                    const token = response.data.access_token;
                    const decoded = jwtDecode(token);
                    
                    // Decode the token to extract email and paid_till
                    const email = decoded.email;
                    const daysLeft = decoded.days_left;
                    
                    // Update the Pinia store
                    const userStore = useUserStore();
                    userStore.setUser(email, daysLeft);
                    this.errorMessage = ''
                    localStorage.setItem('access_token', response.data.access_token);
                    this.$router.push('/services')
                }
                // Handle successful login (e.g., redirect to another page)
            } catch (error) {
                if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                this.errorMessage = error.response.data.detail
                } else if (error.request) {
                // The request was made but no response was received
                this.errorMessage = "No response from server. Please try again later."
                } else {
                // Something happened in setting up the request that triggered an Error
                this.errorMessage = "An error occurred. Please try again."
                }
            }
            }
        }
    } 

  </script>

