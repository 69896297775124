<template>
    <div class="d-flex flex-column filters-block">
      
      <div class="d-flex flex-column" style="gap:18px;">
        <div>
          <label>Категория бизнеса</label>
          <select class="form-control">
            <option selected>Клиники</option>
          </select>
        </div>
        <div>
          <label>Выручка</label>
          <select class="form-control">
            <option selected>от 10 до 100 млн</option>
          </select>
          
          <select class="form-control mt-3">
            <option selected>Увеличилась <span style='color:green'>&#x25B2;</span></option>
            <option>Сократилась <span style='color:green'>&#x25bc;</span></option>
          </select>
        </div>
        <div>
          <label>Сайт</label>
          <select class="form-control">
            <option selected>Есть</option>
          </select>
          <label class="mt-3">Фреймворк</label>
          <select class="form-control">
            <option selected>Битрикс</option>
            <option>Django</option>
            <option>Vue3</option>
            <option>Неважно</option>
          </select>
        </div>
        <div>
          <label>Качество сайта</label>
          <select class="form-control">
            <option selected>Низкое</option>
            <option>Среднее</option>
            <option>Высокое</option>
          </select>
        </div>
        <div class="mt-3">СоцСети</div>
        <div>
          <label>Группа ВК</label>
          <select class="form-control">
            <option selected>Есть</option>
          </select>
          <label class="mt-3">Постов за последние 120 дней</label>
          <select class="form-control">
            <option selected>от 5 до 20</option>
          </select>
          
        </div>
      </div>
      <button class="btn btn-primary mt-3" @click="show_clinics_list">Показать</button>
    </div>
  </template>
  
  <script>
  import axios from '@/assets/js/axios.js';
  import { useClinicsStore } from '@/store/modules/clinics';
  import { useFiltersStore } from '@/store/modules/filters';
  import CitySelect2 from '@/components/sidebar/filters/CitySelect2.vue';
  import { ref, onMounted } from 'vue';
  
  export default {
    name: 'ServiceFilters',
    components: {
      CitySelect2
    },
    setup() {
      const filtersStore = useFiltersStore();
      const select2CategoryInput = ref(null);
      
      const show_clinics_list = () => {
        const clinicsStore = useClinicsStore();
        clinicsStore.setClinics([]);
        console.log(filtersStore.$state)
        axios.get('/api/v1/clinics/basic_clinic_filters', { params: filtersStore.$state })
          .then(response => {
            clinicsStore.setClinics(response.data);
          })
          .catch(error => {
            console.error(error);
            // Handle the error here
          });
      };
  
      const initializeCategorySelect2 = () => {
  axios.get('/admin/select2_get_categories_ajax')
    .then(response => {
      let data = response.data;
      data.unshift({ id: '', text: '' }); // Prepend an empty value
      
      // Initialize Select2 with correct data
      const $select2 = $(select2CategoryInput.value);
      $select2.select2({
        data: data
      });

      // Set initial value and trigger change event
      $select2.val(filtersStore.category_id).trigger('change');
      
      // Listen for change event
      $select2.on('change', (event) => {
        filtersStore.setCategoryId(event.target.value);
      });
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
};
  
      onMounted(() => {
        initializeCategorySelect2();
      });
  
      return {
        filtersStore,
        select2CategoryInput,
        show_clinics_list
      };
    }
  };
  </script>