<template>
    <Navbar/>
    <div class="d-flex" style="margin-top:80px;">
      <RegisterForm/>
    </div>
    <!-- <h1>{{ title }}</h1> -->
  </template>
  
  <script>
  import Navbar from '@/components/navbar/NavbarMain.vue'
  import RegisterForm from '@/components/mainBlock/RegisterForm.vue'
  import '@/assets/css/landing.css'

  export default {
    components: { Navbar, RegisterForm, },
    name: 'Register',
    
  }
  </script>
  
  <style>
  
  </style>