<template>

<div class="is-boxed has-animations">
    <div class="body-wrap boxed-container">
        <div class="d-flex justify-content-between align-items-center header-unit" style="padding:0 100px; z-index: 100">
            <div class='d-flex' style="gap: 12px;">
                <div class="d-flex flex-column align-items-start" style="font-size:2.5rem; line-height: normal">
                    Знаем все
                    <span style="margin-top:-4px; letter-spacing: 9.2px; color:dimgrey; font-size:1rem">стоматологии</span>
                </div>
                <!-- <a href="#">
                        <img class="header-logo-image asset-light" src="@/assets/images/landing/logo-light.svg" alt="Logo">
                        <img class="header-logo-image asset-dark" src="@/assets/images/landing/logo-dark.svg" alt="Logo">
                    </a> -->
            </div>
            <div v-if='!isLoggedIn' class="d-flex" style="gap:18px">
                <a href='/login' class="btn btn-outline-primary button-outline no-decor">Вход</a>
                <a href="/register" class="btn btn-primary button-primary no-decor">Регистрация</a>
            </div>
            <div v-else>
                <a href='/services' class="btn btn-primary button-primary no-decor">Начать пользоваться</a>
            </div>
        </div>
        <main>
            <section class="hero">
                <div class="container">
                    <div class="hero-inner">
						<div class="hero-copy">
	                        <h1 class="hero-title mt-0">Все стоматологии в одном месте</h1>
	                        <p class="hero-paragraph">
                                самая подробная база данных стоматологий с детализацией по услугам
                                </p>
	                        <div class="hero-paragraph">
								<a v-if='isLoggedIn' class="btn button-primary no-decor" href="/payment">Оплатить 10 000 р. за 30 дней</a>
								<a v-else class="btn button-primary no-decor" href="/register">Оплатить 10 000 р. за 30 дней</a>
								<!-- <div class="lights-toggle">
									<input id="lights-toggle" type="checkbox" name="lights-toggle" class="switch" checked="checked">
									<label for="lights-toggle" class="text-xs"><span>Turn me <span class="label-text">dark</span></span></label>
								</div> -->
							</div>
						</div>
						<div class="hero-media">
							<div class="header-illustration">
								<img class="header-illustration-image asset-light" src="@/assets/images/landing/header-illustration-light.svg" alt="">
								<img class="header-illustration-image asset-dark" src="@/assets/images/landing/header-illustration-dark.svg" alt="">
							</div>
							<div class="hero-media-illustration">
								<img class="hero-media-illustration-image asset-light" src="@/assets/images/landing/hero-media-illustration-light.svg" alt="Hero media illustration">
								<img class="hero-media-illustration-image asset-dark" src="@/assets/images/landing/hero-media-illustration-dark.svg" alt="Hero media illustration">
							</div>
							<div class="hero-media-container">
								<img class="hero-media-image asset-light" src="@/assets/images/znaem_vse_screen_1.png" alt="Hero media" style="max-width: 700px;">
								<img class="hero-media-image asset-dark" src="@/assets/images/landing/hero-media-dark.svg" alt="Hero media" style="max-width: 700px;">
							</div>
						</div>
                    </div>
                </div>
            </section>

            <section class="features section">
                <div class="container">
					<div class="features-inner section-inner has-bottom-divider" style="padding-top:24px">
						<div class="features-header text-center">
							<div class="container-sm">
								<h2 class="section-title mt-0 mb-4">База стоматологий<br> Москвы и области</h2>
	                            <p class="section-paragraph">Единственный способ собрать контакты клиник с определенными услугами или оборудованием за считанные секунды</p>
	                            <p class="section-example">Продаете микроскопы — выберите клиники, где их еще нет</p>
	                            <p class="section-example">Занимаетесь обслуживание микроскопов — находите клиники, в которые они уже есть</p>
	                            <p class="section-example" style="background-color: antiquewhite; margin-bottom: 100px">Выгрузка данных одной кнопкой — получайте контакты выбранных клиник в формате эксель</p>
								<div class="features-image">
									<img class="features-illustration asset-dark" src="@/assets/images/landing/features-illustration-dark.svg" alt="Feature illustration">
									<img class="features-box asset-dark" src="@/assets/images/landing/features-box-dark.svg" alt="Feature box">
									<img class="features-illustration asset-dark" src="@/assets/images/landing/features-illustration-top-dark.svg" alt="Feature illustration top">
									<img class="features-illustration asset-light" src="@/assets/images/landing/features-illustration-light.svg" alt="Feature illustration">
									<img class="features-box asset-light" src="@/assets/images/znaem_vse_screen_2.png" alt="Feature box">
									<img class="features-illustration asset-light" src="@/assets/images/landing/features-illustration-top-light.svg" alt="Feature illustration top">
								</div>
							</div>
                        </div>
              
  
					<div class="features-inner section-inner">
						<div class="features-header text-center">
                        <div class="container-sm">
                            <h2 class="section-title mt-0">Инструменты для аналитики</h2>
                            </div>
                            </div>
                    </div>
          
                        <div class="features-wrap">
                            
                            <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img class="asset-icon-light" src="@/assets/images/icons/unique_service.png" alt="Feature 01">
										<img class="asset-dark" src="@/assets/images/landing/feature-01-dark.svg" alt="Feature 01">
                                    </div>
									<div class="feature-content">
                                    	<h3 class="feature-title mt-0">Редкие и уникальные услуги</h3>
                                    	<p class="text-sm mb-0">В один клик узнайте, чего не хватает в вашем городе</p>
									</div>
								</div>
                            </div>
                            <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img class="asset-icon-light" src="@/assets/images/icons/new_service.png" alt="Feature 02">
										<img class="asset-dark" src="@/assets/images/landing/feature-02-dark.svg" alt="Feature 02">
                                    </div>
									<div class="feature-content">
                                    	<h3 class="feature-title mt-0">Новые услуги</h3>
                                    	<p class="text-sm mb-0">Покажем, в каких клиниках появились новинки.</p>
									</div>
								</div>
                            </div>
                            <!-- <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img class="asset-icon-light" src="@/assets/images/icons/xls.png" alt="Feature 03">
										<img class="asset-dark" src="@/assets/images/landing/feature-03-dark.svg" alt="Feature 03">
                                    </div>
									<div class="feature-content">
                                    	<h3 class="feature-title mt-0">Выгрузка данных</h3>
                                    	<p class="text-sm mb-0">Одной кнопкой получайте контакты выбранных клиник в формате эксель.</p>
									</div>
								</div>
                            </div> -->
                            <div class="feature is-revealing">
                                <div class="feature-inner">
                                    <div class="feature-icon">
										<img class="asset-icon-light" src="@/assets/images/icons/price_comparison.png" alt="Feature 01">
										<img class="asset-dark" src="@/assets/images/landing/feature-01-dark.svg" alt="Feature 01">
                                    </div>
									<div class="feature-content">
                                    	<h3 class="feature-title mt-0">Средняя цена категории и сравнение цен</h3>
                                    	<p class="text-sm mb-0">Покажем, сколько в среднем стоит, например, простое удаление зуба в выбранном городе. А также где находится цена услуги в выбранной клинике относительно цен других клиник.</p>
									</div>
								</div>
                            </div>
							
                        </div>
                        <div class="features-image" style="margin-bottom:32px">
                            <img class="features-illustration asset-dark" src="@/assets/images/landing/features-illustration-dark.svg" alt="Feature illustration">
                            <img class="features-box asset-dark" src="@/assets/images/landing/features-box-dark.svg" alt="Feature box">
                            <img class="features-illustration asset-dark" src="@/assets/images/landing/features-illustration-top-dark.svg" alt="Feature illustration top">
                            <img class="features-illustration asset-light" src="@/assets/images/landing/features-illustration-light.svg" alt="Feature illustration">
                            <img class="features-box asset-light" src="@/assets/images/znaem_vse_screen_3.png" alt="Feature box"  style="max-width:660px; z-index:9999">
                            <img class="features-illustration asset-light" src="@/assets/images/landing/features-illustration-top-light.svg" alt="Feature illustration top">
                        </div>
                    </div>
                </div>
            </section>

			<section class="cta section">
                <div class="container-sm">
                    <div class="cta-inner section-inner">
                        <div class="cta-header text-center">
                            <h2 class="section-title mt-0">Окупаемость от 1 дня</h2>
                            <p class="section-paragraph" style="max-width:800px; margin:18px auto">Экономьте время на поиске информации. Мы уже собрали для вас все услуги и цены клиник, разбили по категориям и создали инструменты для аналитики.</p>
							<div class="cta-cta mt-4">
								<a v-if='isLoggedIn' class="btn button-primary no-decor" href="/payment">Оплатить 10 000 р. за 30 дней</a>
								<a v-else class="btn button-primary no-decor" href="/register">Оплатить 10 000 р. за 30 дней</a>
							</div>
                            <div style="margin:64px auto 100px auto; font-style:italic;">P.S. Ваши конкуренты уже получают максимальную пользу от нашего сервиса.</div>
					    </div>
                    </div>
                </div>
            </section>
        </main>

        <footer class="site-footer d-flex justify-content-between align-items-center" style="padding: 0 32px; height:84px">
            
            <div class='d-flex align-items-center' style="gap: 12px;">
                <h3 class="d-flex" style="color:whitesmoke; margin:0">Знаем все</h3>
            </div>
                  
                    <ul class="footer-links list-reset">
                        <li>
                            <a href="#">Контакты</a>
                        </li>
                        <li>
                            <a href="#">О нас</a>
                        </li>

                    </ul>
                  

        </footer>
    </div>

</div>
</template>


<script>
import '@/assets/css/landing.css'
import { ref, onMounted } from 'vue';
import Cookies from 'js-cookie';
import axios from '@/assets/js/axios.js';

export default {
    name: 'Index',
    
    setup() {
        const isLoggedIn = ref(false);

        const checkLoginStatus = () => {
        isLoggedIn.value = localStorage.getItem('access_token');
        };

        // Check login status on component mount
        onMounted(() => {
        checkLoginStatus();
        });
        return{
            isLoggedIn
        }
    },
    async created() {
        const uuid = this.$route.params.uuid;
        if (uuid){
        try {
            await axios.get(`/api/v1/leads/new_user/${uuid}`);
            document.cookie = `new_user=${uuid}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        } catch (error) {
            console.error('Error processing new user request:', error);
        } finally {
            this.$router.replace({ name: 'landing' });
        }
    }
    }
    
}
</script>


<style scoped>


.asset-icon-light{
    width: 80px;
    height: 80px;
}

@media(max-width: 640px){
    .asset-icon-light{
        width:64px;
        height:64px;
    }
}
@media(max-width:640px){
    .header-unit{
        flex-direction: column;
        gap:36px
    }
}

.section-example{
    background-color:rgb(208, 228, 245);
    padding: 24px;

}
</style>