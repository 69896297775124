<template>
    <div class="d-flex flex-column filters-block">
      <div class="d-flex flex-column">
        <label>В скольких клиниках</label>
        <input v-model="filtersStore.number_of_clinics" class="form-control">
      </div>
      <div class="d-flex flex-column">
        <CitySelect2 ref="citySelect2" @change="onSelect2CityChange" />
      </div>
      <button class="btn btn-primary mt-3" @click="show_rare()">Клиники с редкими услугами</button>
      <div style="font-size: .8rem">
        Поиск идет по категориям, которые встречаются в выбранном количестве клиник. При этом в клиниках может быть разное кол-во услуг в одной категории.
      </div>
    </div>
  </template>
  
  <script>
  import axios from '@/assets/js/axios.js';
  import { useClinicsStore } from '@/store/modules/clinics';
  import { useFiltersStore } from '@/store/modules/filters';
  import CitySelect2 from '@/components/sidebar/filters/CitySelect2.vue';
  import { onMounted } from 'vue';
  
  export default {
    name: 'RareCategoryFilters',
    components: {
      CitySelect2
    },
    setup() {
      const filtersStore = useFiltersStore();
      const clinicsStore = useClinicsStore();
  
      // Ensure to use ref for reactive properties
  
      const show_rare = () => {
        clinicsStore.setClinics([]);
        get_clinics_with_rare_categories()
        get_rare_categories()
      };

      const get_clinics_with_rare_categories = () => {
        axios.get('/api/v1/clinics/clinics_with_rare_categories', { params: filtersStore.$state })
          .then(response => {
            clinicsStore.setClinics(response.data);
          })
          .catch(error => {
          });
      }
      const get_rare_categories = () => {
        axios.get('/api/v1/categories/rare_categories', { params: filtersStore.$state })
          .then(response => {
            clinicsStore.setCategories(response.data);
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
      }
      
      const onSelect2CityChange = (value) => {
        filtersStore.setCityName(value);
      };
  
      onMounted(() => {
        // Assuming CitySelect2 component emits the selected city value on change
        // Here you would initialize Select2 or any other setup needed for city selection
      });
  
      return {
        filtersStore,
        show_rare,
        onSelect2CityChange
      };
    }
  };
  </script>