<template>
    <div>
        <Navbar/>
        <div style="display:flex; flex-direction: column; align-items:center; width:500px; max-width:90%; margin:100px auto">
            <h1>Отписка</h1>
            <p v-if="unsubscribed">Вы успешно отписались</p>
            <p v-else-if="error">Что-то пошло не так, напишите в чат внизу страницы, мы исключим ваш адрес в ручную</p>
            <p v-else>Загрузка...</p>
        </div>
    </div>
  </template>
  
  <script>
  import axios from '@/assets/js/axios.js';
  import Navbar from '@/components/navbar/NavbarMain.vue';

  export default {
    components: {
        Navbar
    },
    data() {
      return {
        unsubscribed: false,
        error: false,
      };
    },
    async created() {
      const uuid = this.$route.params.uuid;
      try {
        await axios.get(`/api/v1/leads/unsubscribe/${uuid}/`);
        this.$router.replace({ name: 'UnsubscribeClean' })
        this.unsubscribed = true;
      } catch (error) {
        this.error = true;
        console.error(error);
      }
    },
  };
  </script>