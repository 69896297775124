import { createRouter, createWebHistory } from 'vue-router';
import axios from '@/assets/js/axios.js'

import Landing from '@/views/Landing.vue';
import Services from '@/views/Services.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Profile from '@/views/Profile.vue';
import Payment from '@/views/Payment.vue';
import PaymentMVP from '@/views/PaymentMVP.vue';
import Unsubscribe from '@/views/Unsubscribe.vue';
import LandingWithFilters from '@/views/LandingWithFilters.vue';

const routes = [
  { path: '/', name: 'landing', component: Landing },
  { path: '/new_user/:uuid', name: 'new_user', component: Landing },
  { path: '/services', component: Services, meta: { requiresAuth: true } },
  { path: '/profile', component: Profile, meta: { requiresAuth: true } },
  { path: '/payment', component: Payment, meta: { requiresAuth: true } },
  { path: '/payment_m', component: PaymentMVP },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  {
    path: '/unsubscribe/:uuid',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
  {
    path: '/unsubscribe',
    name: 'UnsubscribeClean',
    component: Unsubscribe,
  },
  { path: '/web_filters', name: 'website_filters', component: LandingWithFilters}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem('access_token') !== null;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      // User is not authenticated, redirect to login
      next({
        path: '/login',
        query: { nextUrl: to.fullPath }, // Optionally pass query parameters
      });
    } else {
      try {
        // User is authenticated, fetch additional data
        const response = await axios.get('/api/v1/users/get_navbar_user_data');
        if (response.data && response.data.days_left === 0 && to.path === '/services') {
          // Redirect to profile if days_left is 0 and trying to access /services
          next({
            path: '/profile',
            query: { nextUrl: to.fullPath },
          });
        } else {
          // Proceed to the requested route
          next();
        }
      } catch (error) {
        // Handle errors from API request
        console.error('Error fetching daysLeft:', error);
        // Redirect to login on error or handle appropriately
        next('/login');
      }
    }
  } else if (to.path === '/login' && isAuthenticated) {
    // Redirect authenticated users trying to access /login to /services
    next('/services');
  } else {
    // Allow access to routes that do not require authentication
    next();
  }

});

export default router;