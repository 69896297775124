<template>
  <div id="map"></div>
</template>

<script>
import { computed, watch, onMounted, onUnmounted } from 'vue';
import { useClinicsStore } from '@/store/modules/clinics';

export default {
  name: 'YandexMap',
  setup() {
    const clinicsStore = useClinicsStore();
    const clinics = computed(() => clinicsStore.clinics);

    let map = null; // Declare map variable and initialize to null

    const initMap = () => {
      if (typeof ymaps === 'undefined') {
        console.error('Yandex Maps API is not loaded.');
        return;
      }

      map = new ymaps.Map("map", {
        center: [55.76, 37.64], // Default center coordinates (Moscow)
        zoom: 10 // Default zoom level
      });

      window.mapInstance = map;
      updateMap(clinics.value);
    };

    const updateMap = (clinics) => {
      if (!map) {
        console.error('Map instance is not defined.');
        return;
      }

      map.geoObjects.removeAll(); // Clear existing placemarks

      const promises = clinics.map(clinic => {
        let coordinatesPromise;

        // Check if coordinates are already present
        if (clinic.lat && clinic.long) {
          coordinatesPromise = Promise.resolve([clinic.lat, clinic.long]);
        } else {
          // Fetch coordinates using geocoding if not present
          coordinatesPromise = ymaps.geocode(clinic.raw_address).then(result => {
            const firstGeoObject = result.geoObjects.get(0);
            return firstGeoObject.geometry.getCoordinates();
          }).catch(error => {
            console.error('Error geocoding clinic:', error);
            return [55.76, 37.64]; // Default coordinates in case of error
          });
        }

        return coordinatesPromise.then(coords => {
          let categoriesContent = '';
          if (clinic.category_set && clinic.category_set.length > 0) {
            categoriesContent = clinic.category_set.map(category => {
              return `${category.index} - ${category.name}`;
            }).join('<br>');
          }

          const balloonContent = `${clinic.brand.name}<br>${clinic.raw_address}<br>${categoriesContent}`;

          const placemark = new ymaps.Placemark(coords, {
            balloonContent: balloonContent,
            closeButton: true
          }, {
            hideIconOnBalloonOpen: false,
            balloonOffset: [0, -40] // Adjust the offset as needed
          });

          map.geoObjects.add(placemark);
          resetMapView(); // Optionally reset map view after adding placemark
        });
      });

      Promise.all(promises).then(() => {
        // All placemarks have been added
      }).catch(error => {
        console.error('Error adding placemarks:', error);
      });
    };

    const resetMapView = () => {
      if (!map) {
        console.error('Map instance is not defined.');
        return;
      }

      map.setCenter([55.76, 37.64]); // Set center to default coordinates (Moscow)
      map.setZoom(10); // Set zoom to default level
    };

    onMounted(() => {
      if (typeof ymaps !== 'undefined') {
        ymaps.ready(initMap);
      } else {
        console.error('Yandex Maps API is not loaded.');
      }
    });

    onUnmounted(() => {
      if (window.mapInstance) {
        window.mapInstance.destroy();
        window.mapInstance = null;
      }
    });

    watch(clinics, (newClinics) => {
      if (window.mapInstance) {
        updateMap(newClinics);
      } else {
        initMap();
      }
    });

    return {
      resetMapView // Expose resetMapView function to template if needed
    };
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100vh;
}
</style>
